/* You can add global styles to this file, and also import other style files */
@layer tw-base, primeng, tw-components, tw-utilities, tw-variants;

@import "tailwindcss/base.css" layer(tw-base);
@import "primeng/resources/primeng.min.css";
@import "primeng/resources/themes/bootstrap4-dark-blue/theme.css";
@import "primeicons/primeicons";
@import "tailwindcss/components.css" layer(tw-components);
@import "tailwindcss/utilities.css" layer(tw-utilities);
@import "tailwindcss/variants.css" layer(tw-variants);


@font-face {
  font-family: 'Reem Kufi Fun';
  src: url('/assets/fonts/ReemKufiFun-Regular.ttf') format('truetype');
  font-weight: 400;
}

body, html{
  margin: 0;
  padding: 0;

  font-family: var(--font-family), serif;

  @apply text-gray-700 dark:text-gray-200
}


ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 0;
}

a {
  text-decoration: none;
}

h1 {
  @apply text-2xl
}

h2 {
  @apply text-xl
}


* {
  border-width: 0;
  border-style: solid;
}

.title {
  font-family: "Reem Kufi Fun", sans-serif;
}

